
.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.hero-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}